@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  //font-family: 'Poppins', sans-serif !important;
  font-family: "Lato", sans-serif !important;
}
mat-icon {
  font-family: "Material Icons" !important;
}
mat-icon.menu-icon {
  height: 100%;
  vertical-align: middle;
  line-height: 43px;
  font-size: 1.3em;
  cursor: pointer;
  //margin-right: 10px;
  background-color: #35314b;
  width: 44px;
  color: #ffffff;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
mat-drawer {
  width: 0px;
}

.header {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #373e5d;
  padding: 10px 0px 10px 0px;
  width: 100%;
  justify-content: space-between;
  // background-color: #ffffff;

  // .header-left-block {
  //   padding-left: 15px;
  // }

  .header-right-block {
    margin-left: auto;
    padding: 4px auto;
    padding-right: 15px;
  }
}
.page-body {
  margin: 15px;
  margin-top: -57px;
}

ul.breadcrums-list {
  padding: 0px 16px;
  padding-left: 0;
  margin-left: 0;
  list-style: none;
}

/* Display list items side by side */
ul.breadcrums-list li {
  display: inline;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrums-list li + li:before {
  padding: 8px;
  color: #373e5d;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrums-list li a {
  color: #82c73a;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrums-list li a:hover {
  color: #91d54a;
  text-decoration: underline;
}

.blue-btn {
  background: #18aaf2;
  border-radius: 50px;
  padding: 7px 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.green-btn {
  background: #91d54a;
  border-radius: 50px;
  padding: 7px 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.gray-btn {
  background: #e6e6e6;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 7px 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #484545;
  cursor: pointer;
}

.orange-btn {
  background: #ff8412;
  border-radius: 50px;
  padding: 7px 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.theme-btn {
  background: #35314b;
  border-radius: 50px;
  padding: 7px 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.mat-tab-label {
  height: 40px !important;
  border: 0px !important;
  box-sizing: border-box;
  border-radius: 0;
  opacity: none !important;
  min-width: 100px !important;
}

.mat-tab-label-active {
  color: #76b731 !important;
  background-color: #fff !important;
  opacity: 1 !important;

  border: 1px solid #76b731 !important;
  box-sizing: border-box;
  border-radius: 5px !important;
  //border-bottom-left-radius: 0 !important;
  //border-bottom-right-radius: 0 !important;
}

.mat-tab-header {
  border: unset;
}

.mat-tab-labels {
  column-gap: 10px;
}

.mat-tab-list .mat-ink-bar {
  display: none !important;
}

/*:host ::ng-deep .mat-form-field-flex{
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding:0px 5px 5px 16px;
  line-height: none;
} 

:host ::ng-deep .cpo-search-block .mat-form-field-flex{
  background: #ffffff !important;
  border: 1px solid #F5EFFC;
  border-radius: 10px;
  padding:0px 5px 9px 16px;
} 


:host ::ng-deep .mat-form-field .mat-form-field-underline{
  height:0px;
}
*/

table {
  width: 100%;
  .mat-header-cell:first-child {
    border-top-left-radius: 5px;
  }
  .mat-header-cell:last-child {
    border-top-right-radius: 5px;
    width: 40px;
  }
  .mat-header-cell {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #1d1b23;
    background-color: #f7f7f7;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .mat-cell {
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
  }

  .mat-cell:last-child .mat-icon {
    cursor: pointer;
  }
}

.table-block {
  padding: 0px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background: #ffffff;
  //margin:10px !important;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}
.mat-paginator {
  background: #f7f7f7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.input-field {
  height: 40px;
  background: #fff;
  border: 1px solid #ccc;
  padding-left: 5px !important;
  input {
    background: #fff;
    border: unset !important;
    padding-left: 3px;
  }
}
input:focus {
  border: unset !important;
}
table,
tr {
  background: #ffffff;
  border-radius: 5px;
}
.mat-form-field-underline {
  display: none !important;
}
.mat-form-field {
  padding-top: 8px;
}
.mat-form-field-label {
  color: #373e5d !important;
}

.station-status-open {
  background: rgba(145, 213, 74, 0.5);
  border-radius: 20px;
  padding: 7px;
}

.station-status-close {
  background: #fd5353;
  padding: 7px;
  border-radius: 20px;
}

.station-status-open-soon {
  background: rgba(255, 171, 45, 0.5);
  padding: 7px;
  border-radius: 20px;
}

.file-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  div {
    background: #f6f6f6;
    border: 0.771605px dashed rgba(161, 161, 161, 0.5);
    box-sizing: border-box;
    border-radius: 6.17284px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100% !important;
    min-height: 100px;
  }
}

.images-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.station-opening-hours {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  padding: 5px;
  .circle {
    border-radius: 50%;
    padding: 10px;
    background: #18aaf2;
    color: #ffffff;
    line-height: 24px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rectangle {
    display: flex;
    align-items: center;
    text-align: center;
    background: #e9ecff;
    border-radius: 4px;
    color: #373e5d;
    padding: 5px;
  }
  .time {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
  }
}

.flex-row-center-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-col-center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.input-field-block {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 10px;
}
.full-width {
  width: 100%;
}

.input-field-block-with-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 5px;
}

.spinner-drop {
  z-index: 9999999;
  position: absolute;
  background: #00000059;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 10px;
  margin-top: 2px;
  margin-right: 5px;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 10px;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 10px;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  padding: 10px;
}

.mid-divider {
  content: "";
  position: relative;
  width: 100%;
  bottom: 0;
  left: 0;
  top: 50%;
  border-top: 1px solid #ebecef;
  .add-circle {
    position: absolute;
    bottom: -11px;
    right: 20px;
  }
}

.white-field {
  input {
    background: #ffffff;
    border-radius: 5px;
    padding: 12px 15px;
    font-size: 14px;
    border: solid 1px #ccc;
    filter: none;
  }
}
.search-block {
  margin-top: 30px;
  display: flex;
  margin-bottom: 20px;
  column-gap: 15px;
  align-items: center;
  .search-block-right {
    margin-left: auto;
  }
}

.box {
  display: flex;
  align-items: stretch;
}
.push {
  margin-left: auto;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0.6em;
}

.mat-paginator-page-size-select {
  margin: 0 !important;
  width: 56px;
}
.breadcrumbs-block {
  display: flex;
  padding-left: 45px;
}

.modal-header-block {
  display: flex;
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;

  h2 {
    margin-bottom: 0;
  }
}
.close-button {
  color: #91d44a;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: #91d54a !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #5e8f2a !important;
}

.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
  margin-bottom: 0px !important;
}

.text-warn {
  color: #ffc107 !important;
}

.tabbed-buttons {
  display: flex;
  column-gap: 25px;
  margin-top: 15px;
  margin-bottom: 30px;

  .mat-primary {
    height: 40px;
    border: solid 1px #82c73a !important;
    color: #82c73a !important;
  }
}

.cpo_name {
  color: #91d54a;
  font-weight: 400 !important;
  cursor: pointer;
}

.connection-text {
  color: #91d54a;
  font-size: 15px;
  font-weight: 600 !important;
}

.mat-card-title {
  font-size: 18px;
}

.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: none;
  border: 1px solid #ccc !important;
}

.mat-card {
  margin-bottom: 20px;
}

.section-top-margin {
  margin-top: 2rem !important;
}

.form-submit-buttons-section {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.progress-bar {
  padding: 0;
}

.progress {
  width: 50px;
}

.mat-input-element {
  font-size: medium;
  font-weight: 200;
}

#fileInput {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  height: 100%;
  left: 0px;
  top: 0px;
}

.mat-toolbar-single-row {
  height: auto !important;
  background: transparent;
  padding: 0;
}

.mat-toolbar-single-row button {
  width: 100px;
}

.mat-form-field {
  width: 100%;
}

.message {
  background-color: #ddd;
  padding: 15px;
  color: #333;
  border: #aaa solid 1px;
  border-radius: 4px;
  margin: 15px 0;
}

.preview {
  max-width: 200px;
  vertical-align: middle;
}

.list-card {
  margin-top: 20px;
}

.list-item {
  margin-bottom: 20px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: #ffffff;
  border-radius: 5px;
}

.a-link {
  color: #91d54a;
  font-weight: 400 !important;
  cursor: pointer;
  text-decoration: none;
}

mat-card-title {
  padding: 0.8rem 0 0.8rem 0;
}
/* Report and Analysis - Custom Cards CSS */
.ev_cards {
  width: 100%;

  mat-grid-tile {
    .mat-grid-tile-content {
      display: block !important;
    }
  }
  .ev_card {
    padding-top: 1rem;
    border: solid 1px #ddd;
    border-radius: 5px;
    min-height: 120px;
    text-align: center;
    .ev_card_title {
      font-size: 15px;
      font-weight: bold;
    }
    .ev_card_value {
      font-size: 45px;
      text-align: center;
    }
    .ev_blue {
      color: #08bade;
    }
    .ev_green {
      color: #0ad1bc;
    }
    .ev_orange {
      color: #e96a73;
    }
  }
}
.mb_3 {
  margin-bottom: 1.5rem !important;
}
.w_100 {
  width: 100% !important;
}
.mr_0 {
  margin-right: 0px !important;
}
.d_block {
  display: block !important;
}

.text-center {
  text-align: center !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.no-data-heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0 1rem 0 !important;
}

.no-data-caption {
  font-size: 0.8rem;
  color: #787878;
}

.no-data-container {
  padding: 5rem !important;
}

.flex-justify-end {
  display: flex;
  justify-content: space-between;
}

.mt-2 {
  margin-top: 1rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.clear {
  clear: both;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after,
.gm-style .gm-style-iw-tc::after {
  background: #35314b;
  .map-charging-station-info {
    background: red;
  }
  table {
    background: red;
  }
}

.map-charging-station-info,
.map-charging-station-info tr,
.map-charging-station-evses-info {
  background: #35314b;
}

.map-charging-station-info tr td {
  color: #00ccff;
}

.map-charging-station-info tr td strong {
  color: #fff;
}

.map-charging-station-evses-info thead tr td {
  color: #fff;
}

.actionBtn {
 color: rgb(33 33 33);
}

.success-dialog {
  color: white !important;
  background-color: #91d54a!important;
  .mat-simple-snackbar-action {
      color: white !important;
  }
}

.error-dialog {
  color: white !important;
  background-color: red!important;
  .mat-simple-snackbar-action {
      color: white !important;
  }
}